@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Roboto+Mono&display=swap");

body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: Roboto Mono, source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body,
#root {
    height: 100%;
}

a {
    color: #00529c;
}

input,
select {
    font-family: "Open Sans", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
}

/**
* Re-set default cursor for disabled elements.
*/
button[disabled],
html input[disabled] {
    cursor: default;
}

sup {
    font-size: x-small;
    font-weight: 800;
}
